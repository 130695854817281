import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
} from "@reduxjs/toolkit";
import { fetchPlatformsWithSkillsAPI } from "../../apis/platform";

const platformsAdapter = createEntityAdapter({
  selectId: (platform) => platform.id,
  sortComparer: (a, b) => a.name.localeCompare(b.name),
});

const initialState = platformsAdapter.getInitialState({
  status: "idle",
  error: null,
});

export const fetchPlatforms = createAsyncThunk(
  "platforms/fetchPlatforms",
  async (_, { rejectWithValue }) => {
    try {
      const response = await fetchPlatformsWithSkillsAPI();
      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const platformsSlice = createSlice({
  name: "platforms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlatforms.pending, (state) => {
        state.status = "loading";
        state.error = null;
      })
      .addCase(fetchPlatforms.fulfilled, (state, action) => {
        state.status = "succeeded";
        platformsAdapter.setAll(state, action.payload);
      })
      .addCase(fetchPlatforms.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload || "Failed to load platforms";
      });
  },
});

export default platformsSlice.reducer;

export const { selectAll: selectAllPlatforms, selectById: selectPlatformById } =
  platformsAdapter.getSelectors((state) => state.platforms);
