import { httpCall } from "../httpCalls";

export const getUtilizationChartDataAPI = async ({
  startDate,
  endDate,
  member_ids,
}) => {
  const utilization_chart_data_response = await httpCall({
    type: "GET",
    url: "utilizations",
    data: {
      from_date: startDate,
      to_date: endDate,
      member_ids,
    },
  });
  const utilizatoin_chart_data = utilization_chart_data_response.data.data;
  return utilizatoin_chart_data;
};

export const getTimeEntryDashboardDataAPI = async ({
  startDate,
  endDate,
  resource_ids,
}) => {
  const timeEntryResponse = await httpCall({
    type: "GET",
    url: "dashboard/time-entries",
    data: {
      start_date: startDate,
      end_date: endDate,
      resource_ids,
    },
  });
  const timeEntriesData = timeEntryResponse.data;
  return timeEntriesData;
};

export const getCalculatedProjectStatusAPI = async ({
  isArchieved = "False",
  startDate,
  endDate,
  managers = "all",
  leads = "all",
}) => {
  const calculatedPHData = await httpCall({
    type: "GET",
    url: "dashboard/project-health",
    data: {
      isArchieved,
      start_date: startDate,
      end_date: endDate,
      managers,
      leads,
    },
  });
  const PHData = calculatedPHData.data;
  return PHData;
};

export const getProjectHealthReportsAPI = async ({ projectId }) => {
  const historicPHData = await httpCall({
    type: "GET",
    url: `dashboard/project-health/${projectId}`,
  });
  const PHData = historicPHData.data;
  return PHData;
};

export const submitProjectHealthReportAPI = async ({ report }) => {
  const { workspace_id } = report;
  const historicPHData = await httpCall({
    type: "POST",
    url: `dashboard/project-health/${workspace_id}`,
    data: report,
  });
  const PHData = historicPHData.data;
  return PHData;
};

export const getCertificationDashboardAPI = async (data) => {
  const {
    certification_id = null,
    platform_id = null,
    status_filter = null,
    expiry_filter = null,
    user_ids = null,
  } = data || {};
  const certificationDashboardResponse = await httpCall({
    type: "GET",
    url: `dashboard/certifications`,
    data: {
      certification_id,
      platform_id,
      status_filter,
      expiry_filter,
      user_ids,
    },
  });
  const certificationData = certificationDashboardResponse.data.data;
  return certificationData;
};

export const assignCertificationAPI = async ({
  deadline,
  member_id,
  certification_id,
  notes = null,
  assigned_by_id,
}) => {
  const certificationDashboardResponse = await httpCall({
    type: "POST",
    url: `dashboard/certifications`,
    data: {
      deadline,
      member_id,
      certification_id,
      notes,
      assigned_by_id,
    },
  });
  const certificationData =
    certificationDashboardResponse.data.data.assigned_certificates;
  return certificationData;
};
