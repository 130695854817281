import React, { useEffect, useState } from "react";
import {
  Button,
  Popover,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Autocomplete,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchCertificates,
  selectAllCertificates,
} from "../../../redux/reducers/certificates";
import {
  fetchPlatforms,
  selectAllPlatforms,
} from "../../../redux/reducers/platforms";

const FilterPopover = ({ handleFilterChange }) => {
  const dispatch = useDispatch();

  const [anchorEl, setAnchorEl] = useState(null);
  const [certificationId, setCertificationId] = useState(null);
  const [platformId, setPlatformId] = useState(null);
  const [statusFilter, setStatusFilter] = useState(null);
  const [expiryFilter, setExpiryFilter] = useState(null);

  const certificates = useSelector(selectAllCertificates);
  const platforms = useSelector(selectAllPlatforms);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    handleFilterChange({
      certification_id: certificationId,
      platform_id: platformId,
      status_filter: statusFilter,
      expiry_filter: expiryFilter,
    });
    handleClose();
  };

  const handleReset = () => {
    // Reset all filter values to their default (initial) state
    setCertificationId(null);
    setPlatformId(null);
    setStatusFilter(null);
    setExpiryFilter(null);
  };

  useEffect(() => {
    if (certificates.length === 0) {
      dispatch(fetchCertificates());
    }
  }, [certificates.length, dispatch]);

  useEffect(() => {
    if (platforms.length === 0) {
      dispatch(fetchPlatforms());
    }
  }, [platforms.length, dispatch]);

  const open = Boolean(anchorEl);
  const id = open ? "filter-popover" : undefined;

  return (
    <div>
      <Button variant="contained" onClick={handleClick}>
        Filter
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <div
          style={{
            padding: "20px",
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: "300px",
          }}
        >
          <FormControl fullWidth>
            <Autocomplete
              options={[{ id: null, name: "All" }, ...certificates]}
              getOptionLabel={(option) => option.name || "All"}
              value={
                certificates.find((cert) => cert.id === certificationId) || null
              }
              onChange={(e, newValue) =>
                setCertificationId(newValue ? newValue.id : null)
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Certification"
                  variant="outlined"
                />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <Autocomplete
              options={[{ id: null, name: "All" }, ...platforms]}
              getOptionLabel={(option) => option.name || "All"}
              value={
                platforms.find((platform) => platform.id === platformId) || null
              }
              onChange={(e, newValue) =>
                setPlatformId(newValue ? newValue.id : null)
              }
              renderInput={(params) => (
                <TextField {...params} label="Platform" variant="outlined" />
              )}
            />
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="status-filter-label">Status</InputLabel>
            <Select
              label="Status"
              labelId="status-filter-label"
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value)}
            >
              <MenuItem value={null}>All</MenuItem>
              <MenuItem value="Expired">Expired</MenuItem>
              <MenuItem value="Maintenance Due">Maintenance Due</MenuItem>
              <MenuItem value="Current">Current</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="expiry-filter-label">Expiry</InputLabel>
            <Select
              labelId="expiry-filter-label"
              value={expiryFilter}
              label="Expiry"
              onChange={(e) => setExpiryFilter(e.target.value)}
            >
              <MenuItem value={null}>None</MenuItem>
              <MenuItem value="Current Month">Current Month</MenuItem>
              <MenuItem value="Current Quarter">Current Quarter</MenuItem>
              <MenuItem value="Next Quarter">Next Quarter</MenuItem>
            </Select>
          </FormControl>

          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button variant="outlined" color="secondary" onClick={handleReset}>
              Reset
            </Button>
            <Button variant="contained" color="primary" onClick={handleSubmit}>
              Apply Filters
            </Button>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default FilterPopover;
